/* eslint-disable jsx-a11y/no-static-element-interactions */
import '../StepwiseFlow.css';
import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Handle, Position } from 'reactflow';

import ArrowDown from '../../../../../../assets/icons/down-arrow.svg';
import InfoIcon from '../../../../../../assets/icons/InfoIconNoFill.svg';
import {
  updateShowStepwiseSidePanel,
  updateSelectedModuleDataForDrillDown
} from '../../../../../../reducers/stepwise';
import { getPercentage } from '../../../../../../utils/helpers';
import CommonPopover from '../../../CommonPopover';
import HoverDetails from '../../HoverDetails';
import { convertMstoMinutesSeconds, getIconForSubType } from '../../utils';
import AddGoToEdges from '../GoToNodes/AddGoToEdges';
import HideGoToEdges from '../GoToNodes/HideGoToEdges';

function LabelNode({ data }) {
  const dispatch = useDispatch();
  const medianTime = convertMstoMinutesSeconds(
    _.get(data.stepwiseNodeData, `medianTimeSpent`, '-')
  );

  const hoverMetricsTableConfig = [
    ...(data?.subType === 'countries'
      ? [
          {
            label: 'Total users',
            value: _.get(data, ['stepwiseNodeData', 'totalTransactionsCount'])
          }
        ]
      : [])
  ];

  const dropOffHoverTableConfig = [
    {
      label: 'Drop Off %',
      value: `${getPercentage(data.dropOff, data.stepwiseNodeData.totalTransactionsCount)}%`
    },
    {
      label: 'Users Lost on this module',
      value: `${data.dropOff}`
    }
  ];

  const handleNodeClick = () => {
    if (data.dropOff > 0) {
      const moduleData = {
        moduleName: data.label,
        moduleId: data.nodeId
      };
      dispatch(updateSelectedModuleDataForDrillDown(moduleData));
      dispatch(updateShowStepwiseSidePanel(true));
    }
  };

  return (
    <div id={data?.nodeId} className="label_node">
      {data.dropOff > 0 && (
        <div className="label_node_drop_off_container">
          <hr className="label_node_drop_off_hr" />
          <div className="label_node_drop_off_arrow" />
          <div className="label_node_drop_off">
            <CommonPopover
              body={
                <HoverDetails
                  trigger={['hover', 'focus']}
                  popoverClass="stepwise-popover"
                  title="Drop Offs"
                  tableConfig={dropOffHoverTableConfig}
                />
              }
              iconClass="label_drop_off_icon"
              icon={ArrowDown}
            />
          </div>
          <p>{getPercentage(data.dropOff, data.stepwiseNodeData.totalTransactionsCount)}%</p>
        </div>
      )}
      <div className="label_border" onClick={handleNodeClick}>
        <Handle type="target" position={Position.Top} />
        <div className="label_node_top">
          <div className="label_node_icon">
            {React.cloneElement(getIconForSubType(data.subType))}
          </div>
          <div className="label_node_heading">{data.label}</div>
          <AddGoToEdges data={data} idPath="nodeId" />
          <HideGoToEdges data={data} />
          {hoverMetricsTableConfig.length > 0 && (
            <div className="info-icon">
              <CommonPopover
                body={
                  <HoverDetails
                    trigger={['hover', 'focus']}
                    popoverClass="stepwise-popover"
                    title={data.label}
                    tableConfig={hoverMetricsTableConfig}
                  />
                }
                icon={InfoIcon}
              />
            </div>
          )}
        </div>
        <div className="label_node_stats">
          <div className="label_node_stats_body">
            <span className="label_node_stats_heading">Median Time:&nbsp;</span>
            <span className="label_node_stats_data">{medianTime}</span>
          </div>
          <div className="label_node_stats_body">
            <span className="label_node_stats_heading">Median Attempts:&nbsp;</span>
            <span className="label_node_stats_data">
              {_.get(data.stepwiseNodeData, 'medianAttempts', 0)}
            </span>
          </div>
        </div>
        <Handle type="source" position={Position.Bottom} id="out" />
      </div>
    </div>
  );
}

LabelNode.propTypes = {
  data: PropTypes.object.isRequired
};

export default LabelNode;
