/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

const initState = {
  nodes: [],
  edges: [],
  hiddenEdges: [],
  dropOffs: [],
  highRetakes: [],
  backPressed: [],
  highTime: [],
  activeGoto: '',
  activeHotspot: '',
  renderGraph: false,
  showStepwiseSidePanel: false,
  selectedModuleDataForDrillDown: { moduleName: null, moduleId: null }
};

export const stepwiseSlice = createSlice({
  name: 'stepwise',
  initialState: initState,
  reducers: {
    updateActiveGoto: (state, action) => {
      const { activeGoto } = action.payload;
      state.activeGoto = activeGoto;
    },
    updateActiveHotspot: (state, action) => {
      const { activeHotspot } = action.payload;
      state.activeHotspot = activeHotspot;
    },
    updateStepwiseGraph: (state, action) => {
      const { nodes, edges, hiddenEdges } = action.payload;
      state.nodes = nodes || initState.nodes;
      state.edges = edges || initState.edges;
      state.hiddenEdges = hiddenEdges || initState.hiddenEdges;
    },
    updateHotspots: (state, action) => {
      const { dropOffs, highRetakes, highTime, backPressed } = action.payload;
      state.dropOffs = dropOffs || initState.dropOffs;
      state.highRetakes = highRetakes || initState.highRetakes;
      state.highTime = highTime || initState.highTime;
      state.backPressed = backPressed || initState.backPressed;
    },
    updateBackPressedCounts: (state, action) => {
      const { backPressed } = action.payload;
      state.backPressed = backPressed;
    },
    updateRenderGraph: (state, action) => {
      const { renderGraph } = action.payload;
      state.renderGraph = renderGraph;
    },
    updateShowStepwiseSidePanel: (state, action) => {
      state.showStepwiseSidePanel = action.payload;
    },
    updateSelectedModuleDataForDrillDown: (state, action) => {
      const { moduleName, moduleId } = action.payload;
      set(state, 'selectedModuleDataForDrillDown.moduleName', moduleName);
      set(state, 'selectedModuleDataForDrillDown.moduleId', moduleId);
    },
    reset: (state) => {
      state = initState;
      return state;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  updateActiveGoto,
  updateActiveHotspot,
  updateStepwiseGraph,
  updateHotspots,
  reset,
  updateRenderGraph,
  updateShowStepwiseSidePanel,
  updateSelectedModuleDataForDrillDown
} = stepwiseSlice.actions;
export default stepwiseSlice.reducer;
