import React from 'react';

import PropTypes from 'prop-types';

import { formatDateForDropdown } from '../../../../../utils/formatDate';

function Header({ filters, moduleSelected }) {
  return (
    <div className="module__side_panel__header__container">
      <div className="module__side_panel__title">
        <h1>Drill Down - {moduleSelected.moduleName}</h1>
      </div>
      <p>
        Date Range is &quot;
        {formatDateForDropdown(
          filters?.dateRange?.startDate,
          filters?.dateRange?.endDate
        )}&quot; <span>Where</span> Business Unit is &quot;
        {filters.useCase}&quot; <span>&</span> App ID is &quot;{filters.appId}&quot; <span>&</span>{' '}
        Workflow ID is &quot;{filters.workflowId}&quot;
      </p>
    </div>
  );
}

Header.propTypes = {
  filters: PropTypes.object.isRequired,
  moduleSelected: PropTypes.object.isRequired
};

export default Header;
