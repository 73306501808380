import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/ArrowDown.svg';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/Search.svg';
import { updateSelectedModuleDataForDrillDown } from '../../../../../reducers/stepwise';

function Filter({ availableFilterList, onFiltersApply }) {
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const selectedModule = useSelector((state) => state?.stepwise?.selectedModuleDataForDrillDown);
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(availableFilterList);
  const [optionFilterValue, setOptionFilterValue] = useState('');
  const [showApplyButton, setShowApplyButton] = useState(false);

  useEffect(() => {
    const dropdownEventListener = (event) => {
      event.stopPropagation();
      if (event.target !== dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };

    window.addEventListener('click', (event) => dropdownEventListener(event));

    return window.removeEventListener('click', (event) => dropdownEventListener(event));
  }, []);

  // Handles click on dropdown
  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  // Handle input filter search value changes
  const handleFilterValueChange = (event) => {
    setOptionFilterValue(event.target.value);
  };

  // Handles selection of an option
  const handleOptionClick = (value) => {
    if (value === selectedModule.moduleId) return;
    let selectedObj = availableFilterList.find((val) => val.moduleId === value);

    selectedObj = selectedObj
      ? { moduleName: selectedObj.label, moduleId: selectedObj.moduleId }
      : null;

    dispatch(updateSelectedModuleDataForDrillDown(selectedObj));
    setShowOptions(false);
    setShowApplyButton(true);
  };

  // Hides apply button and calls parent component handle apply click function
  const handleApplyClick = (event) => {
    event.stopPropagation();
    setShowApplyButton(false);
    onFiltersApply(selectedModule);
  };

  // Hides options and updates selected option on change of hotspot click
  useEffect(() => {
    setShowOptions(false);
  }, [selectedModule]);

  // Resets filtered options on hotspot array change
  useEffect(() => {
    setFilteredOptions(availableFilterList);
  }, [availableFilterList]);

  // Filters out options on change of input value in search bar
  useEffect(() => {
    if (optionFilterValue === '') {
      setFilteredOptions(availableFilterList);
      return;
    }

    const filteredValues = availableFilterList.filter((filter) =>
      filter?.label?.toLowerCase()?.includes(optionFilterValue?.toLowerCase())
    );
    setFilteredOptions(filteredValues);
  }, [optionFilterValue]);

  // Bring the selected option into view on expanding dropdown
  useEffect(() => {
    if (showOptions) {
      const selectedOptionElement = document.querySelector(
        '.module__side_panel__filter_option_container.selected'
      );
      selectedOptionElement.scrollIntoView();
    }
  }, [showOptions]);

  return (
    <div className="module__side_panel__filter_container">
      <div className="module__side_panel__filter_dropdown_container">
        <div
          ref={dropdownRef}
          aria-hidden
          onClick={handleDropdownClick}
          className="module__side_panel__filter_dropdown">
          <p>
            Module Name: <span>{selectedModule?.moduleName}</span>
          </p>
          <ArrowIcon className={`module__side_panel__filter_icon ${showOptions ? 'rotate' : ''}`} />
        </div>
        {showApplyButton && (
          <button onClick={handleApplyClick} type="button">
            Apply
          </button>
        )}
      </div>
      {showOptions && (
        <div className="module__side_panel__filter_options_container">
          <div
            onClick={(event) => event.stopPropagation()}
            aria-hidden
            className="module__side_panel__filter_options_search_container">
            <SearchIcon />
            <input
              value={optionFilterValue}
              onChange={handleFilterValueChange}
              placeholder="Search Module Name"
            />
          </div>
          <div className="module__side_panel__filter_list_container">
            {filteredOptions.map((option) => {
              return (
                <div
                  key={option.moduleId}
                  aria-hidden
                  onClick={() => handleOptionClick(option.moduleId)}
                  className={`module__side_panel__filter_option_container ${
                    selectedModule.moduleId === option.moduleId ? 'selected' : ''
                  }`}>
                  <p>{option.label}</p>
                  <p>{option.value}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

Filter.propTypes = {
  availableFilterList: PropTypes.array.isRequired,
  onFiltersApply: PropTypes.func.isRequired
};

export default Filter;
