import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Body from './Body';
import Header from './Header';
import getRetentionDays from '../../../../../api/config';
import { updateDataRetentionDays } from '../../../../../reducers/config';
import SidePanel from '../../../../Shared/SidePanel';

import './ModuleSidePanel.css';

function ModuleSidePanel({ showSidePanel, onSidePanelClose, filters }) {
  const selectedModuleDataForDrillDown = useSelector(
    (state) => state.stepwise.selectedModuleDataForDrillDown
  );
  const filterDataForModuleSidePanel = useSelector((state) => state.stepwise.dropOffs);
  const dispatch = useDispatch();
  const getRetentionDaysData = async (appId) => {
    if (!appId) return;

    const retentionDays = await getRetentionDays(appId);
    dispatch(updateDataRetentionDays({ dataRetentionDays: retentionDays }));
  };

  useEffect(() => {
    getRetentionDaysData(filters.appId);
  }, [filters]);

  return (
    <SidePanel showSidePanel={showSidePanel} onSidePanelClose={onSidePanelClose}>
      <Header filters={filters} moduleSelected={selectedModuleDataForDrillDown} />
      <Body filters={filters} availableFilterList={filterDataForModuleSidePanel} />
    </SidePanel>
  );
}

ModuleSidePanel.defaultProps = {
  showSidePanel: true,
  onSidePanelClose: () => {}
};

ModuleSidePanel.propTypes = {
  showSidePanel: PropTypes.bool,
  onSidePanelClose: PropTypes.func,
  filters: PropTypes.object.isRequired
};

export default ModuleSidePanel;
