import * as Sentry from '@sentry/react';
import axios from 'axios';
import get from 'lodash/get';

import { checkTokenExpiry } from '../actions/user';
import getAPICallMetadataHeaders from '../utils/storeUtils';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/analytics`,
  withCredentials: true
});

const getAnalytics = async (appIds, dateRange, workflowId) => {
  try {
    const headers = getAPICallMetadataHeaders();
    const res = await axiosRequest.post(
      '/funnel',
      {
        appIds,
        dateRange,
        workflowId: workflowId === 'All Workflows' ? undefined : workflowId
      },
      {
        headers
      }
    );
    return res.data.result;
  } catch (err) {
    Sentry.captureException(err);
    checkTokenExpiry(get(err, 'response.status'));
    return { apiError: true };
  }
};

export const getFlagAnalytics = async (appIds, dateRange, workflowId) => {
  try {
    const headers = getAPICallMetadataHeaders();
    const res = await axiosRequest.post(
      '/flags',
      {
        appIds,
        dateRange,
        workflowId: workflowId === 'All Workflows' ? undefined : workflowId
      },
      { headers }
    );
    return res.data.result;
  } catch (err) {
    checkTokenExpiry(get(err, 'response.status'));
    Sentry.captureException(err);
    return { apiError: true };
  }
};

export const getStepwiseAnalytics = async (appId, workflowId, dateRange) => {
  try {
    const res = await axiosRequest.post(
      '/stepwise',
      { appId, workflowId, dateRange },
      {
        headers: {
          appid: appId,
          ...getAPICallMetadataHeaders()
        }
      }
    );
    return res.data.result;
  } catch (error) {
    checkTokenExpiry(get(error, 'response.status'));
    Sentry.captureException(error);
    return { apiError: true };
  }
};

export const getFunnelHotspotTransactions = async ({
  appId,
  workflowId,
  dateRange,
  moduleId,
  flag,
  statusSelected
}) => {
  try {
    const res = await axiosRequest.post(
      '/funnel/hotspot/transactions',
      {
        appId,
        workflowId: workflowId === 'All Workflows' ? undefined : workflowId,
        dateRange,
        ...(moduleId ? { moduleId } : {}),
        flag,
        status: statusSelected
      },
      {
        headers: {
          appid: appId,
          ...getAPICallMetadataHeaders()
        }
      }
    );
    return get(res, 'data.result', {});
  } catch (error) {
    return { apiError: true };
  }
};

export const getMetabaseStaticEmbeddingLinks = async () => {
  try {
    const res = await axiosRequest.get('/metabase/embedding/static/links', {
      headers: {
        ...getAPICallMetadataHeaders()
      }
    });
    return {
      links: get(res, 'data.result.links', [])
    };
  } catch (error) {
    checkTokenExpiry(get(error, 'response.status'));
    Sentry.captureException(error);
    return { apiError: true };
  }
};

export const getDropOffApplicationsForAModule = async ({
  appId,
  workflowId,
  dateRange,
  moduleId
}) => {
  try {
    const res = await axiosRequest.post('/stepwise/hotspot/drop-offs/transactions', {
      appId,
      workflowId,
      dateRange,
      moduleId
    });
    return get(res, 'data.data.dropOffTransactions', []);
  } catch (error) {
    Sentry.captureException(error);
    return { apiError: true };
  }
};

export default getAnalytics;
