import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';

import './SidePanel.css';

function SidePanel({ showSidePanel, children, onSidePanelClose }) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`side_panel__main__container ${showSidePanel ? '' : 'hidden'}`}
      onClick={onSidePanelClose}>
      <CloseIcon aria-hidden onClick={onSidePanelClose} className="side_panel__close_button" />
      <div
        aria-hidden
        className="side_panel__body__container"
        onClick={(event) => event.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}

SidePanel.defaultProps = {
  showSidePanel: false,
  children: []
};

SidePanel.propTypes = {
  showSidePanel: PropTypes.bool,
  children: PropTypes.array,
  onSidePanelClose: PropTypes.func.isRequired
};

export default SidePanel;
