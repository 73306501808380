import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Filter from './Filter';
import KnowMoreModal from './KnowMoreModal';
import { getDropOffApplicationsForAModule } from '../../../../../api/analytics';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/InfoIcon2.svg';
import Table from '../../../../Shared/Table';

function Body({ availableFilterList, filters }) {
  const [transactionData, setTransactionData] = useState([]);
  const selectedModule = useSelector((state) => state.stepwise.selectedModuleDataForDrillDown);
  const showDrillDownSidePanel = useSelector((state) => state.stepwise.showStepwiseSidePanel);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const displayKnowMoreModal = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const hideKnowMoreModal = (event) => {
    event.stopPropagation();
    setShowModal(false);
  };

  const onFiltersApply = async (selectedFilter) => {
    setIsLoading(true);
    try {
      setTransactionData([]);
      const obj = {
        ...filters,
        moduleId: selectedFilter.moduleId
      };
      const data = await getDropOffApplicationsForAModule(obj);
      if (data.apiError) {
        setTransactionData([]);
        setIsLoading(false);
        return;
      }
      setTransactionData(data);
    } catch (error) {
      Sentry.captureException(error);
      setTransactionData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    onFiltersApply(selectedModule);
    setShowModal(false);
  }, [showDrillDownSidePanel]);

  return (
    <div className="module__side_panel__body__container">
      <KnowMoreModal showModal={showModal} onHide={hideKnowMoreModal} />
      <Filter availableFilterList={availableFilterList} onFiltersApply={onFiltersApply} />
      <div className="module__side_panel__body__info_container">
        <p>
          <InfoIcon className="module__side_panel__body__info_icon" />
          Application status could have changed more recently
          <span onClick={displayKnowMoreModal} aria-hidden>
            Know more
          </span>
        </p>
      </div>
      <div className="module__side_panel__transactions_table_container">
        <Table isLoading={isLoading} transactionData={transactionData} filters={filters} />
      </div>
    </div>
  );
}

Body.propTypes = {
  availableFilterList: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired
};

export default Body;
